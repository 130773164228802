import React, { Component } from "react";
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsReddit,
  BsQuestionCircleFill,
  BsArrowUpRightSquareFill,
} from "react-icons/bs";
// import PropTypes from 'prop-types'

export default class Footer extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   }

  constructor(props) {
    super(props);

    this.currentYear = new Date().getFullYear();
  }

  render() {
    return (
      <>
        <div className="container py-4 py-md-5 px-4 px-md-3 font-14px">
          <div className="row">
            <div className="col-12 col-lg-4 mb-3 pe-5">
              <span
                className="navbar-brand brand-logo"
                href="/"
                aria-label="Atrowel"
              >
                Atrowel
              </span>
              <ul className="list-unstyled small pe-4">
                <li className="mb-2">
                  Atrowel is powered by the incredible SRSS community and the
                  company.
                  {/* © 2021-2022 SRSS. All rights reserved. */}
                </li>
                <li>
                  <a
                    className="m-1 me-2"
                    target="_blank" rel="noopener noreferrer"
                    href="https://www.facebook.com/Atrowel-61550471352111"
                    aria-label="Atrowel Facebook Page"
                  >
                    <BsFacebook size="16px" />
                  </a>
                  <a
                    className="m-1 me-2"
                    target="_blank" rel="noopener noreferrer"
                    href="https://twitter.com/AtrowelCom"
                    aria-label="Atrowel Twitter Page"
                  >
                    <BsTwitter size="16px" />
                  </a>
                  <a
                    className="m-1"
                    target="_blank" rel="noopener noreferrer"
                    href="https://www.instagram.com/atrowelcom/"
                    aria-label="Atrowel Instagram Page"
                  >
                    <BsInstagram size="16px" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="row col-12 col-lg-8 mb-3">
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">CONVERTERS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/json-to-string/">JSON to String</a>
                  </li>
                  <li className="mb-2">
                    <a href="/json-to-xml/">JSON to XML</a>
                  </li>
                  <li className="mb-2">
                    <a href="/json-to-csv/">JSON to CSV</a>
                  </li>
                  <li className="mb-2">
                    <a href="/string-to-json/">String to JSON</a>
                  </li>
                  <li className="mb-2">
                    <a href="/xml-to-json/">XML to JSON</a>
                  </li>
                  <li className="mb-2">
                    <a href="/csv-to-json/">CSV to JSON</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">IMAGE TOOLS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/crop-image/">Image Crop</a>
                  </li>
                  <li className="mb-2">
                    <a href="/rotate-image/">Image Rotate</a>
                  </li>
                  <li className="mb-2">
                    <a href="/flip-image/">Image Flip</a>
                  </li>
                  <li className="mb-2">
                    <a href="/resize-image/">Image Resize</a>
                  </li>
                  <li className="mb-2">
                    <a href="/compress-image/">Image Compress</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">XML TOOLS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/xml-formatter/">XML Formatter</a>
                  </li>
                  <li className="mb-2">
                    <a href="/xml-minifier/">XML Minifier</a>
                  </li>
                  <li className="mb-2">
                    <a href="/xml-compare/">XML Compare</a>
                  </li>
                  <li className="mb-2">
                    <a href="/xml-escape/">XML Escape</a>
                  </li>
                  <li className="mb-2">
                    <a href="/xml-unescape/">XML Unescape</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">IMAGE CONVERTERS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/convert-to-jpg/">Convert To JPG</a>
                  </li>
                  <li className="mb-2">
                    <a href="/convert-to-png/">Convert To PNG</a>
                  </li>
                  <li className="mb-2">
                    <a href="/convert-to-bmp/">Convert To BMP</a>
                  </li>
                  <li className="mb-2">
                    <a href="/convert-to-webp/">Convert To WEBP</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">SERIES</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/introduction-to-java-programming/">Core Java Tutorial</a>
                  </li>
                  <li className="mb-2">
                    <a href="/json-introduction/">
                    Json Tutorial{" "}
                      <BsArrowUpRightSquareFill className="mt-n4" size="15px" />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/javascript-tutorial/">JavaScript Tutorial</a>
                  </li>
                  <li className="mb-2">
                    <a href="/linux-command-line/">Linux Command Line Tutorial</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">JSON TOOLS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/json-formatter/">JSON Formatter</a>
                  </li>
                  <li className="mb-2">
                    <a href="/json-minifier/">JSON Minifier</a>
                  </li>
                  <li className="mb-2">
                    <a href="/json-compare/">JSON Compare</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">OTHER TOOLS</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/diff-compare-merge/">File Compare</a>
                  </li>
                  <li className="mb-2">
                    <a href="/character-count-online/">Character Counter</a>
                  </li>
                </ul>
              </div>
              
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">ABOUT</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/about/">About Atrowel</a>
                  </li>
                  <li className="mb-2">
                    <a href="/contact/">
                      Contact{" "}
                      <BsQuestionCircleFill className="mt-n2" size="15px" />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/donate/">Donate</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 mb-3">
                <h5 className="footer-tag">COMPANY</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="/privacy/">Privacy Policy</a>
                  </li>
                  <li className="mb-2">
                    <a href="/terms/">
                    Terms of Use
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="/cookies/">Cookie Policy</a>
                  </li>
                  <li className="mb-2">
                    <a href="/disclaimer/">Disclaimer</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-top border-white-10 my-7"></div>
          <p className="text-center text-muted mt-2">
            Copyrights © 2021-{this.currentYear} SRSS. All Rights Reserved.
          </p>
        </div>
      </>
    );
  }
}
