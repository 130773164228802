import * as StrConst from "../constants/StrConstants";

// NOTE : 1. Google generally truncates snippets to ~155-160 characters. 
//           So recommend descriptions between 50 and 160 characters.

export const md_home = {
  path: StrConst.pageurl.home + StrConst.URL_INDEX,
  title: "Atrowel - 🚀 Explore the world of programming languages with us, 💻 Unlock your coding potential today! #Coding",
  description:
    "Discover Atrowel, your gateway to free and comprehensive learning. Dive into a world of programming languages across various subjects, all accompanied by a wealth of practical examples.",
  keywords:
    "Online Image Cropper, Convert To JPG, Convert To PNG, Online JSON Formater, Java tutorial, JavaScript, Online File diff checker, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_about = {
  path: StrConst.pageurl.about + StrConst.URL_INDEX,
  title: "About",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_contact = {
  path: StrConst.pageurl.contact + StrConst.URL_INDEX,
  title: "Contact",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_donate = {
  path: StrConst.pageurl.donate + StrConst.URL_INDEX,
  title: "Donate",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_privacy = {
  path: StrConst.pageurl.privacy + StrConst.URL_INDEX,
  title: "Privacy Policy",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_terms = {
  path: StrConst.pageurl.terms + StrConst.URL_INDEX,
  title: "Terms - Privacy Policy",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_cookie = {
  path: StrConst.pageurl.cookies + StrConst.URL_INDEX,
  title: "Cookies - Privacy Policy",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_disclaimer = {
  path: StrConst.pageurl.disclaimer + StrConst.URL_INDEX,
  title: "Disclaimer - Privacy Policy",
  description:
    "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
  keywords:
    "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_sitesetup = {
//   path: StrConst.pageurl.getSS + StrConst.URL_INDEX,
//   title: "ATrowel - Explore and Learn basic concepts of coding with Atrowel!",
//   description:
//     "Explore and Learn basic concepts of Java, Spring, Spring Boot, REST with Atrowel!",
//   keywords:
//     "JSon Tool, Java tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
//   image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
//   imgWidth: "1140",
//   imgHeight: "533",
// };

// JSON
export const md_json_intro = {
  path: StrConst.pageurl.jsonIntroduction + StrConst.URL_INDEX,
  title: "JSON Introduction",
  description:
    "Understand what is json? Basic Structure of JSON and JSON Syntax.",
  keywords:
    "JSON Introduction, Basic of JSON, JSON Tutorial, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_write_java = {
  path: StrConst.pageurl.writeJSONInJAVA + StrConst.URL_INDEX,
  title: "How to write and parse JSON in Java?",
  description:
    "Using different JSON Libraries function JSONParser, JSONObject and JSONArray will parse and write JSON in java. ",
  keywords:
    "JSON Libraries, JSON Parse in Java, JSON Read in Java, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_json_write_java = {
//   path: "/write-json-data-java",
//   title: "How to write JSON in Java?",
//   description:
//     "Using different JSON Libraries function JSONParser, JSONObject and JSONArray will create/write JSON in java.",
//   keywords:
//     "JSON Libraries, JSON write in Java, JSON create in Java, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

export const md_json_write_cpp = {
  path: StrConst.pageurl.writeJSONInCpp + StrConst.URL_INDEX,
  title: "How to write and parse JSON in C++?",
  description:
    "Using Nlohmann JSON Librarie function json::parse, JSON Object and JSON Array will parse and write JSON in cpp.",
  keywords:
    "Nlohmann JSON Libraries, JSON Parse in C++, JSON Read in C++, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_json_write_cpp = {
//   path: "/write-json-data-cpp",
//   title: "How to write JSON in C++?",
//   description:
//     "Using Nlohmann JSON Librarie function json::parse, JSON Object and JSON Array will create/write JSON in cpp.",
//   keywords:
//     "Nlohmann JSON Libraries, JSON write in C++, JSON create in C++, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

export const md_json_write_c = {
  path: StrConst.pageurl.writeJSONInC + StrConst.URL_INDEX,
  title: "How to write and parse JSON in C?",
  description:
    "Using cJSON Librarie function cJSON_Parse, cJSON_Print, cJSON_CreateObject and cJSON_CreateArray will parse and write JSON in c.",
  keywords:
    "cJSON Libraries, JSON Parse in C, JSON Read in C, JSON to XML, JSON to CSV, cJSON_Parse, cJSON_Print, cJSON_CreateObject and cJSON_CreateArray",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_json_write_c = {
//   path: "/write-json-data-c",
//   title: "How to write JSON in C?",
//   description:
//     "Using cJSON Librarie function cJSON_Parse, cJSON_Print, cJSON_CreateObject and cJSON_CreateArray will create/write JSON in c.",
//   keywords:
//     "cJSON Libraries, JSON Parse in C, JSON Read in C, JSON to XML, JSON to CSV, cJSON_Parse, cJSON_Print, cJSON_CreateObject and cJSON_CreateArray",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

export const md_json_write_python = {
  path: StrConst.pageurl.writeJSONInPython + StrConst.URL_INDEX,
  title: "How to write and parse JSON in Python?",
  description:
    "Using JSON Librarie function JSON Parser, JSON Object and JSON Array will parse and write JSON in python.",
  keywords:
    "JSON Libraries, JSON write in Python, JSON create in Python, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_json_write_python = {
//   path: "/write-json-data-python",
//   title: "How to write JSON in Python?",
//   description:
//     "Using JSON Librarie function JSON Parser, JSON Object and JSON Array will create/write JSON in python.",
//   keywords:
//     "JSON Libraries, JSON write in Python, JSON create in Python, JSON to XML, JSON to CSV, JSON to String, JSON Validator, JSON Formatter, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

export const md_json_formatter = {
  path: StrConst.pageurl.jsonFormatter + StrConst.URL_INDEX,
  title: "JSON Formatter & Validator",
  description:
    "Online JSON Formatter / Beautifier & Validator will help beatify JSON data and help to validate JSON data.",
  keywords:
    "JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON to String, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_compare = {
  path: StrConst.pageurl.jsonCompare + StrConst.URL_INDEX,
  title: "JSON Compare Online",
  description:
    "JSON Compare Online will help to identify differences between the two JSON objects.",
  keywords:
    "JSON Compare Online, JSON diff checker Online, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON to String, JSON Converter, JSON Minifier, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_minifier = {
  path: StrConst.pageurl.jsonMinifier + StrConst.URL_INDEX,
  title: "JSON Minifier",
  description:
    "Online JSON Minifier will help to compress JSON, remove extra space, lines from JSON.",
  keywords:
    "JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON to String, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_to_string = {
  path: StrConst.pageurl.jsonToString + StrConst.URL_INDEX,
  title: "Convert JSON to String",
  description:
    "Online Convert JSON to String will help to convert JSON in String formate, remove extra space, lines from JSON.",
  keywords:
    "JSON to String, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_strin_to_json = {
  path: StrConst.pageurl.stringToJson + StrConst.URL_INDEX,
  title: "Convert String to JSON",
  description:
    "Online Convert String to JSON will help to parse String and convert JSON Object.",
  keywords:
    "String to JSON, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_to_xml = {
  path: StrConst.pageurl.jsonToXML + StrConst.URL_INDEX,
  title: "Convert JSON to XML",
  description:
    "Online Convert JSON to XML will help to convert JSON data to XML formate.",
  keywords:
    "JSON to XML, String to JSON, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_xml_to_json = {
  path: StrConst.pageurl.xmlToJSON + StrConst.URL_INDEX,
  title: "Convert XML to JSON",
  description:
    "Online Convert XML to JSON will help to convert XML data to JSON formate.",
  keywords:
    "XML to JSON, JSON to XML, String to JSON, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON to CSV, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_json_to_csv = {
  path: StrConst.pageurl.jsonToCSV + StrConst.URL_INDEX,
  title: "Convert JSON to CSV",
  description:
    "Online Convert JSON to CSV will help to convert JSON data to CSV formate.",
  keywords:
    "JSON to CSV, XML to JSON, JSON to XML, String to JSON, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_csv_to_json = {
  path: StrConst.pageurl.csvToJSON + StrConst.URL_INDEX,
  title: "Convert CSV to JSON",
  description:
    "Online Convert CSV to JSON will help to convert CSV data to JSON formate.",
  keywords:
    "CSV to JSON, JSON to CSV, XML to JSON, JSON to XML, String to JSON, JSON Minifier, JSON Compare, JSON diff, JSON Validator, JSON Formatter, JSON Beautifier, JSON to XML, JSON Converter, JSON Formatter, download JSON",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// Image
export const md_image_crop = {
  path: StrConst.pageurl.cropImg + StrConst.URL_INDEX,
  title: "Image crop",
  description: "Online Free PNG, JPG, SVG Image Crop, rotate, flip with rectangle, square shape.",
  keywords:
    "Image Crop, Image rotate, Image flip, Image Resize, Image Compress, Tiny Image, PNG to JPG, JPG to PNG, Crop SVG, SVG to PNG, PNG Image Crop, JPG Image Crop",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_rotate = {
  path: StrConst.pageurl.rotateImg + StrConst.URL_INDEX,
  title: "Image rotate",
  description: "Online Free PNG, JPG, SVG Image Crop, rotate, flip with rectangle, square shape.",
  keywords:
    "Image Crop, Image rotate, Image flip, Image Resize, Image Compress, Tiny Image, PNG to JPG, JPG to PNG, Crop SVG, SVG to PNG, PNG Image Crop, JPG Image Crop",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_flip = {
  path: StrConst.pageurl.flipImg + StrConst.URL_INDEX,
  title: "Image flip",
  description: "Online Free PNG, JPG, SVG Image Crop, rotate, flip with rectangle, square shape.",
  keywords:
    "Image Crop, Image rotate, Image flip, Image Resize, Image Compress, Tiny Image, PNG to JPG, JPG to PNG, Crop SVG, SVG to PNG, PNG Image Crop, JPG Image Crop",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_resize = {
  path: StrConst.pageurl.resizeImg + StrConst.URL_INDEX,
  title: "Image resize",
  description: "Online Free PNG, JPG, SVG Image resize with rectangle, square shape.",
  keywords:
    "Image resize, Image rotate, Image flip, Image Resize, Image Compress, Tiny Image, PNG to JPG, JPG to PNG, Crop SVG, SVG to PNG, PNG Image Crop, JPG Image Crop",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_compress = {
  path: StrConst.pageurl.compressImg + StrConst.URL_INDEX,
  title: "Image compress",
  description: "Online Free PNG, JPG, SVG Image compress with rectangle, square shape.",
  keywords:
    "Image compress, Image rotate, Image flip, Image Resize, Image Compress, Tiny Image, PNG to JPG, JPG to PNG, Crop SVG, SVG to PNG, PNG Image Crop, JPG Image Crop",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};


export const md_image_con_jpg = {
  path: StrConst.pageurl.convertJPG + StrConst.URL_INDEX,
  title: "Convert images to JPEG",
  description: "Online JPEG converter will convert any readable image into a JPG image.",
  keywords:
    "JPEG converter, converter to jpg, png to jpg, jpg converter, converter svg to jpg, webp to jpg, free jpg converter",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_con_png = {
  path: StrConst.pageurl.convertPNG + StrConst.URL_INDEX,
  title: "Convert images to PNG",
  description: "Online PNG converter will convert any readable image into a PNG image.",
  keywords:
    "PNG converter, converter to PNG, jpg to png, PNG converter, converter svg to PNG, webp to PNG, free PNG converter",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_con_bmp = {
  path: StrConst.pageurl.convertBMP + StrConst.URL_INDEX,
  title: "Convert images to BMP",
  description: "Online BMP converter will convert any readable image into a BMP image.",
  keywords:
    "BMP converter, converter to BMP, png to BMP, BMP converter, converter svg to BMP, webp to BMP, free BMP converter",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_image_con_webp = {
  path: StrConst.pageurl.convertWEBP + StrConst.URL_INDEX,
  title: "Convert images to webp",
  description: "Online webp converter will convert any readable image into a webp image.",
  keywords:
    "webp converter, converter to webp, png to jpg, webp converter, converter svg to webp, webp to webp, free webp converter",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// File
export const md_file_diff_merge = {
  path: StrConst.pageurl.fileDiffCompare + StrConst.URL_INDEX,
  title: "File Diffchecker - Compare differences between any two files",
  description: "File Diffchecker will compare line by line to identify the difference between line, word and character. It will help you to merge those line, word and character.",
  keywords: "File Diffchecke, online file diff, diff tool, quick diff, diff checker, file merger, diff, file compare online",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// Character Counter
export const md_character_counter = {
  path: StrConst.pageurl.characterCounter + StrConst.URL_INDEX,
  title: "Character Count - Online Character/Word Counter Websites",
  description: "Character Count allows you to paste or type your text, and it will display the character count along with other statistics like word count and character density.",
  keywords: "character count, character count in word, character count online, word character count, character count tool, online character count, word count in word, word count checker, word count on word, paragraph counter online, character count in word,   excel character count, twitter character count, character count excel, character count online",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// XML
export const md_xml_formatter = {
  path: StrConst.pageurl.xmlformatter + StrConst.URL_INDEX,
  title: "XML Formatter - Online XML Formatter and Beautifier",
  description: "Enhance XML Readability: Online XML Formatter and Beautifier is a valuable tool for organizing and presenting XML data in a structured, visually appealing manner. Explore this tool to make your XML content more readable and user-friendly.",
  keywords: "XML Formatter, Online XML Formatting, XML Beautifier, Format XML Online, XML Formatting Tool, XML Beautification, XML Structure Enhancement, XML Data Presentation, Improve XML Readability, Structured XML Output, User-Friendly XML, Online XML Format Service, XML Content Organization, Enhanced XML Data, XML Code Beautification",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_xml_minifier = {
  path: StrConst.pageurl.xmlminifier + StrConst.URL_INDEX,
  title: "XML Minifier - Online XML Compresses Tool",
  description: "Optimize XML Data: Online XML Minifier is a powerful tool that compresses and reduces the size of XML files, enhancing efficiency and performance. Explore this tool for streamlined XML data optimization.",
  keywords: "XML Minifier, Online XML Compression, Compress XML, Minify XML Files, XML Size Reduction, XML File Optimizer, Efficient XML Data Minimization, Online XML Minimizer, Reduce XML Size, XML Compression Tool, Streamlined XML Data, Optimize XML Files, XML Data Size Reduction, XML File Compressor, XML Minification Service",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_xml_compare = {
  path: StrConst.pageurl.xmlcompare + StrConst.URL_INDEX,
  title: "XML Compare - Online XML Diff, XML Compare and Merger Tool",
  description: "Online Compare and Merge XML Data: Online XML Compare tool simplifies the process of identifying differences between XML files and merging them with ease. ",
  keywords: "XML Compare, Online XML Diff, XML Compare and Merge, XML Data Comparison, XML File Merger, XML Comparison Tool, Compare and Merge XML Files, XML Difference Checker, Online XML Comparison, Efficient XML Data Management, Merge XML Files Online, Compare and Merge XML Documents, XML Diff Tool, Streamlined XML File Comparison, Automated XML Data Merger",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_xml_escape = {
  path: StrConst.pageurl.xmlescape + StrConst.URL_INDEX,
  title: "Online XML Escape - Escape Special Characters",
  description: "XML Special Character Escaping: Online XML Escape tool empowers you to quickly and securely escape special characters in XML data, ensuring data integrity and compliance.",
  keywords: "XML Escape, Online XML Character Escaping, Escape Special Characters in XML, XML Data Integrity, Secure XML Escaping, Compliance with XML Standards, XML Character Escape Tool, Special Character Handling, XML Data Security, Escaping XML Entities, Online XML Character Encoder, Data Sanitization in XML, Streamlined XML Character Escaping, Secure XML Data Handling, XML Compliance Solutions",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_xml_unescape = {
  path: StrConst.pageurl.xmlunescape + StrConst.URL_INDEX,
  title: "Online XML Unescape - Unescape Special Characters",
  description: "XML Special Character Unescaping: Our online XML Unescape tool enables you to swiftly and securely reverse character escaping in XML data, ensuring accurate and clean data representation.",
  keywords: "XML Unescape, Online XML Character Unescaping, Reverse Escaped Characters in XML, Accurate XML Data Representation, Secure XML Unescaping, Data Integrity in XML, XML Character Unescape Tool, Clean XML Data, Accurate Data Rendering, XML Data Decoding, Online XML Character Decoder, Data Validation in XML, Streamlined XML Character Unescaping, Secure XML Data Representation, XML Data Clean-up Solutions",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// export const md_xml_to_string = {
//   path: StrConst.pageurl.xmltostring + StrConst.URL_INDEX,
//   title: "XML To String Converter - Online Stringify XML",
//   description: "Online XML to Stringify Conversion: Online XML to Stringify Converter effortlessly and efficiently transforms XML structures into plain text strings, making data processing a breeze. Experience the best XML-to-Stringify conversion.",
//   keywords: "XML to String Converter, Convert XML to String, Online XML to Stringify, XML data to string, XML to Stringify tool, Export XML as string, XML to Stringify conversion, XML to plain text online, Convert XML to structured string, Stringify XML data, Extract strings from XML, Easy XML to Stringify conversion, Efficient XML to Stringify tool, Structured data to string conversion, XML to Stringify converter online",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

/* Unable to find correct module */
// export const md_xml_to_csv = {
//   path: StrConst.pageurl.xmltocsv + StrConst.URL_INDEX,
//   title: "XML To CSV Converter - Convert XML Data to CSV Data",
//   description: "Online XML to CSV Conversion: Seamlessly transform XML data into structured CSV files for easy analysis and sharing.",
//   keywords: "XML to CSV Converter, Convert XML to CSV, XML data to CSV, XML to CSV tool, Export XML to CSV, XML to CSV conversion, XML to CSV online, Convert XML to CSV file, CSV format for XML, Convert XML data to spreadsheet, XML to CSV converter online, Easy XML to CSV conversion, Efficient XML to CSV tool, Streamlined XML to CSV conversion, Convert structured data from XML to CSV",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

// export const md_xml_to_text = {
//   path: StrConst.pageurl.xmltotext + StrConst.URL_INDEX,
//   title: "XML To TEXT Converter - Convert XML Data to Text Data",
//   description: "Online XML to Text Conversion: Online XML to Text Converter simplifies the process of transforming XML data into plain text for easy data extraction and sharing.",
//   keywords: "XML to Text Converter, Convert XML to Text, Online XML to Text, XML data to plain text, XML to Text tool, Export XML as plain text, XML to Text conversion, XML to plain text online, Convert XML to unformatted text, Text data from XML, Extract text from XML, Easy XML to Text conversion, Efficient XML to Text tool, Structured data to plain text, XML to unformatted text converter",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// };

//Bash
export const md_commandline_introduction = {
  path: StrConst.pageurl.commandLineIntro + StrConst.URL_INDEX,
  title: "Linux Command Line - Overview",
  description: "Explore the power and versatility of the Linux Command Line on our platform. Discover essential commands, gain proficiency in navigating the file system, and unleash the potential of the command line interface.",
  keywords: "Linux kernel, GNU utilities, The Linux Desktop Environment, Linux Application software, Software program Management, System Memory Management, Filesystem Management",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_commandline_terminal = {
  path: StrConst.pageurl.commandLineTerminal + StrConst.URL_INDEX,
  title: "Accessing the command line",
  description: "Discover Linux terminals like GNOME, Konsole, and Xterm on our user-friendly platform. Explore GNOME for its simplicity, Konsole for KDE environments, and the classic Xterm.",
  keywords: "Linux Terminal, Terminal Emulator, GNOME, Konsole, Xterm, Terminal Shortcuts, Terminal Navigation",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

// JAVA
export const md_java_introduction = {
  path: StrConst.pageurl.javaIntro + StrConst.URL_INDEX,
  title: "Introduction to Java Programming",
  description: "Java Programming Language is a high level language which is based on OOP concepts. It is one of the most popular multi-platform programming languages used to develop a variety of applications including video games, Web Interface, and business applications.",
  keywords: "Java, Programming Language, Assembly Language, Machine Language, Low Level Language, High Level Laanguage, OOP concepts, POP, Portable, Secure, Robust, Platform Independent, Web application, Mobile applications, Supercomputer, Multithreaded ",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_env_setup = {
  path: StrConst.pageurl.javaEnvSetup + StrConst.URL_INDEX,
  title: "Java Environment Setup",
  description: "Java Environment Setup - Installing the JDK using command line, and Integrated Development Environment.",
  keywords: "Java Environment Setup, java install for ubuntu, set java environment variable ubuntu",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_jshell = {
  path: StrConst.pageurl.javaJShell + StrConst.URL_INDEX,
  title: "Java Shell Tool(JShell)",
  description: "JShell is a tool for learning java. It is an interactive tool. JShell provides a read-evaluate print loop (REPL). It evaluates the statements, expressions, etc, and displays the result immediately.",
  keywords: "JShell, JShell Commands, Java tool, variables, methods, commands, math, net, file, concurrent, function, prefs, regex, stream",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_datatype = {
  path: StrConst.pageurl.javaDataTypes + StrConst.URL_INDEX,
  title: "Data Types in Java",
  description: "Primitive Data type - Primitive data types are the predefined data types of Java that specify the size and the type of any standard values.",
  keywords: "Java, Data Types, Primitive data type, Non-Primitive data type, boolean, int, short, long, float, double, char, Class, Object, String, Array, Interface",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_nonprimitive = {
  path: StrConst.pageurl.javaNonPrimitive + StrConst.URL_INDEX,
  title: "Non-Primitive Data Types in Java",
  description: "Non-primitive data types are the data types that are created by programmers. They're not predefined in java like primitive data types. Non Primitive data types are used to store a group of values or several values.",
  keywords: "Non- Primitive, variable, memory location, heap memory, class, object, string, array, interface",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_variables = {
  path: StrConst.pageurl.javaVariables + StrConst.URL_INDEX,
  title: "Variables in Java",
  description: "Variables are generally used to store the values. When the program is executed, values get stored in the memory locations and each memory location has a memory address that is hexadecimal.",
  keywords: "variables, rules, initialization, local variables, instance variables, static variables, primitive datatypes, non-primitive datatypes, constructor, hexadecimal, memory address",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_operators = {
  path: StrConst.pageurl.javaOperators + StrConst.URL_INDEX,
  title: "Operators in Java",
  description: "In general, operators are used for performing some calculations. The same is applicable in Java, operators are used for performing operations on variables like addition, subtraction, etc.",
  keywords: "operators, arithmetic operators, relational operators, conditional operators, assignment operators, ternary operators, unary operators, bitwise operators.",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_bitwise_operators = {
  path: StrConst.pageurl.javaBitwiseOperators + StrConst.URL_INDEX,
  title: "Bitwise Operators in Java",
  description: "Java provides bitwise operators used to perform bitwise operations on integer values. These operators directly work on bits, meaning that for getting individual bits in numbers we can use masking techniques.",
  keywords: "bitwise AND, bitwise OR, bitwise XOR, bitwise Complement, bitwise Left Shift, Bitwise Right Shift, Unsigned Right Shift",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_string = {
  path: StrConst.pageurl.javaString + StrConst.URL_INDEX,
  title: "Strings in Java",
  description: "In Java String is a sequence of characters i.e. the character array. There is no built-in type for string. Rather, the Java library contains a predefined class which is called String.",
  keywords: "string, immutable, a string literal, a new keyword, JVM",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_string_method_part1 = {
  path: StrConst.pageurl.javaStringMethod1 + StrConst.URL_INDEX,
  title: "String Method in Java - Part-1",
  description: "getBytes() - getBytes() is used for encoding the string into a sequence of bytes using UTF-8(default charset of the platform) and storing its result in the new byte array.",
  keywords: "index, destination, sourceBeginIndex, destinationBeginIndex, delimiter",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_string_method_part2 = {
  path: StrConst.pageurl.javaStringMethod2 + StrConst.URL_INDEX,
  canonical: StrConst.pageurl.javaStringMethod1 + StrConst.URL_INDEX,
  title: "String Method in Java - Part-2",
  description: "replaceAll() - replaceAll() method gives us the string replacing all the sequence of characters matching regular expressions and replacement string.",
  keywords: "regular expression, delimiter, index, startIndex, endIndex, offset, prefix",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_string_buffer = {
  path: StrConst.pageurl.javaStringBuffer + StrConst.URL_INDEX,
  title: "StringBuffer class in Java",
  description: "StringBuffer works the same way String works but only the difference is StringBuffer is mutable (the contents of StringBuffer can be changed).",
  keywords: "StringBuffer, String, mutable, float, int, char, mutable, storage, performance, memory, immutable",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_input_output = {
  path: StrConst.pageurl.javaInputOutput + StrConst.URL_INDEX,
  title: "Input and Output in Java",
  description: "Input in Java is the data that is given to the program/code and the output is the data that we receive from the program in the form of result.",
  keywords: "StringBuffer, String, Input, Output, Scanner, Console class, PrintWriter, PrintStream, cursor, character encoding",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_controle_flow = {
  path: StrConst.pageurl.javaControlFlow + StrConst.URL_INDEX,
  title: "Control Flow in Java",
  description: "To control the flow of Java code, some statements are provided, such statements are called Control Flow Statements.",
  keywords: "block scope, nested block, if statement, if-else, if-else-if, flowchart ",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_loops = {
  path: StrConst.pageurl.javaLoops + StrConst.URL_INDEX,
  title: "Loops in Java",
  description: "We use loops to repeat a block of the program multiple times until the specific condition satisfies.",
  keywords: "loops, control flow, while, do-while, for",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_array = {
  path: StrConst.pageurl.javaArray + StrConst.URL_INDEX,
  title: "Arrays in Java",
  description: "Array is the collection of the same types of data that stores the elements in a contiguous manner. Contiguous is nothing but the elements are stored in a sequential manner i.e. 1st element on the 0th index position, 2nd element on the 1st index position, and so on.",
  keywords: "array, anonymous, accessing array elements, sequential manner, command line arguments, sorting array, multi-dimensional array",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_object_class = {
  path: StrConst.pageurl.javaObjectClass + StrConst.URL_INDEX,
  title: "Objects and Classes in Java",
  description: "OOP stands for Object Oriented Programming Language. It has replaced the structured programming techniques developed in the 1970s.",
  keywords: "Object, Class, OOP, POP, encapsulation, state, behavior, identity, dependence, aggregation, inheritance",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_predefined_class = {
  path: StrConst.pageurl.javaPredefinedClass + StrConst.URL_INDEX,
  title: "Predefined Classes in Java",
  description: "A predefined class name is a name that can be used as a class name. Class name has main() method.",
  keywords: "Predefined Classes, Math class, LocalDate class, null pointers, uninitialized pointers, accessor, mutator",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_defined_own_class = {
  path: StrConst.pageurl.javaDefinedOwnClass + StrConst.URL_INDEX,
  title: "Defining Own Classes in Java",
  description: "When we create an application there are multiple classes defined and each class contains the instance variables and methods. The syntax of creating our own class.",
  keywords: "defining own class, rules of the constructor, var keyword, null references, implicit parameters, explicit parameters, encapsulation, private method",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_static_method = {
  path: StrConst.pageurl.javaStaticMethod + StrConst.URL_INDEX,
  title: "Static Methods and Variables in Java",
  description: "When we define static variables in our code, then please make a note that there is only one static variable per class. On the contrary, each object has its own copy of non-static instance variables.",
  keywords: "static variables, static instances, non-static instances, static constants, factory method, main() method",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_object_construction = {
  path: StrConst.pageurl.javaObjectConstruction + StrConst.URL_INDEX,
  title: "Object Construction and Destruction in Java",
  description: "Just like OOP, Java also supports overloading. Overloading is when the write more than one constructor or method having the same names for constructors and methods but differ in the return type and no of parameters is called overloading.",
  keywords: "constructor overloading, method overloading, default constructor, field initialization, explicit filed initialization, initialization blocks, object destructor",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_packages = {
  path: StrConst.pageurl.javaPackages + StrConst.URL_INDEX,
  title: "Introduction to Packages",
  description: "Packages allow to group classes in a collection, and it also prevents conflicts in names, etc.",
  keywords: "packages, the purpose of using packages, importing classes, static import, public, private, protected access specifiers",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_jar = {
  path: StrConst.pageurl.javaJAR + StrConst.URL_INDEX,
  title: "JAR(Java ARchive) Files",
  description: "JAR stands for Java ARchive. The main purpose of the JAR files was to give users a single file, not a directory that contained multiple class files. JAR Files can contain class files as well as other files like image and sound files",
  keywords: "JAR(Java ARchive)Files, manifest, zip, META-INF, MANIFEST.MF",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_inheritance = {
  path: StrConst.pageurl.javaInheritance + StrConst.URL_INDEX,
  title: "Inheritance in Java",
  description: "The basic idea behind inheritance is that we can create new classes built on existing classes. When we inherit a new class from its existing class then we can reuse its methods and also we can add new methods or fields to it.",
  keywords: "inheritance, object-oriented programming, subclass, reusability, superclass, method overriding, subclass constructor, polymorphism, inheritance hierarchy, method calls, runtime polymorphism, data hiding",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_inheritance_types = {
  path: StrConst.pageurl.javaInheritanceTypes + StrConst.URL_INDEX,
  title: "Types of Inheritance in Java",
  description: "In this blog, we will go through the different types of inheritance Single Inheritance, Multilevel inheritance, Hierarchical Inheritance, Multiple Inheritance(using Interface), and Hybrid Inheritance(using Inheritance)",
  keywords: "inheritance, single inheritance, multilevel inheritance, hierarchical inheritance, multiple inheritance using the interface,  hybrid inheritance using inheritance, inherit, extends, implements ",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_final_keyword = {
  path: StrConst.pageurl.javaFinalKeyword + StrConst.URL_INDEX,
  title: "Final Keyword in Java",
  description: "Classes that cannot be extended are called final classes. In order to indicate the class as final we write the final keyword in the definition of that class.",
  keywords: "final, inheritance access modifiers, methods, class, final class, final method, dynamic binding, final variables, type casting, widening casting, narrowing casting, implicit casting, explicit casting.",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_abstract_keyword = {
  path: StrConst.pageurl.javaAbstractKeyword + StrConst.URL_INDEX,
  title: "Abstract Class in Java",
  description: "Abstract class is nothing but a class that is declared abstract is called an Abstract class. An abstract class can have abstract and non abstract methods.",
  keywords: "abstract class, final, abstraction, non abstract, abstract method",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_object = {
  path: StrConst.pageurl.javaObject + StrConst.URL_INDEX,
  title: "Object Class in Java",
  description: "Object class is the ancestor for every class. Every class in Java extends the Object class. If there is no superclass mentioned explicitly, then Object is considered a superclass. As every class in Java extends the Object class, it's important to be familiar with the services provided by it.",
  keywords: "Object Class, Abstract Class, abstract methods, cosmic superclass, equals, superclass, subclass, reflexive, symmetric, transitive, consistent, toString, instanceOf",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_arraylist = {
  path: StrConst.pageurl.javaArrayList + StrConst.URL_INDEX,
  title: "ArrayList in Java",
  description: "ArrayList is similar to an array,  but it has the ability to adjust its capacity when the elements are added or removed.",
  keywords: "ArrayList, Object class, add((), ensureCapacity(), size(), accessing the ArrayList",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_wrapper_class = {
  path: StrConst.pageurl.javaWrapperClass + StrConst.URL_INDEX,
  title: "Wrapper Classes in Java",
  description: "Suppose we want to convert the primitive type i.e. int to an object. All primitive types have classes. For example, class Integer belongs to the primitive type int. These classes are called Wrapper.",
  keywords: "Wrapper Classes, ArrayList, primitive type, immutable, final, autoboxing, subclass, enum, final variable, enumerate type, private, public, toString()",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_reflection = {
  path: StrConst.pageurl.javaReflection + StrConst.URL_INDEX,
  title: "Reflection in Java",
  description: "Reflection is a feature in Java that allows an executing Java code to examine or introspect upon itself, and manipulate internal properties of the program.",
  keywords: "Reflection, Wrapper Classes, reflective library, dynamic, interface builder, object-relational mappers, generic array, Virtual Machine, Exception, checked exception, unchecked exception, NullPointer Exception, ArrayIndexOutOfBound Exception,  resource, JVM, internationalization, localization",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_reflection_part2 = {
  path: StrConst.pageurl.javaReflectionPar2 + StrConst.URL_INDEX,
  canonical: StrConst.pageurl.javaReflection + StrConst.URL_INDEX,
  title: "Reflection in Java Part 2",
  description: "The Reflection mechanism is very powerful. We can use Reflection to analyze the capabilities classes at runtime, inspect objects at runtime, implement generic array manipulation code, and take advantage of Method objects that work just like function pointers in languages like C++.",
  keywords: "Reflection, Generic Array, reflection mechanism, Modifier class, Arbitrary Methods, Arbitrary Constructors",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_interaface = {
  path: StrConst.pageurl.javaInterface + StrConst.URL_INDEX,
  title: "Interface in Java",
  description: "Interface is a mechanism used for describing what a class should do without describing how they should do it. In other words, Interface is a mechanism used to achieve abstraction.",
  keywords: "Interface, reflection, abstraction, inheritance, array, comparable interface, instanceOf, cloneable, compatibility, abstract class, multiple inheritance, static method private method",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_interaface_part_2 = {
  path: StrConst.pageurl.javaInterfacePart2 + StrConst.URL_INDEX,
  canonical: StrConst.pageurl.javaInterface + StrConst.URL_INDEX,
  title: "Interface in Java Part 2",
  description: "Java 8 introduced a new concept called default methods in the interface. Methods that are defined inside the interface and have a default keyword in the definition are called default methods. Default methods were introduced to solve the compatibility issues in Java.",
  keywords: "interface, default methods, compatibility, superclass, overriding, parameter type, callback, ambiguity, object oriented",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
};

export const md_java_lambda_expressions = {
  path: StrConst.pageurl.javaLambdaExpressions + StrConst.URL_INDEX,
  title: "Lambda Expressions in Java",
  description: "Lambda Expression is a block of code that takes in the parameter and gives value in return. They are the same as methods but do not require a name and can be implemented right in the body of a method",
  keywords: "lambda expressions, interface, callback using interface, object oriented, string comparator, functional interface, Comparator, method references, instanceMethod, static Method, super class",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_lambda_expressions_part_2 = {
  path: StrConst.pageurl.javaLambdaExpressionsPart2 + StrConst.URL_INDEX,
  canonical: StrConst.pageurl.javaLambdaExpressions + StrConst.URL_INDEX,
  title: "Lambda Expressions in Java Part 2",
  description: "Constructor Reference behaves the same way as method reference but the difference is that rather than referencing the method it maps the functional interface with the constructor of the class.",
  keywords: "constructor reference, variable scope, comparator, functional interface",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_inner_classes = {
  path: StrConst.pageurl.javaInnerClasses + StrConst.URL_INDEX,
  title: "Inner Classes in Java",
  description: "The Inner Class is nothing but the class which is defined inside the other class. There are two reasons for using the Inner Class:Inner Class can access all the members of the outer class including private members.Inner Class can be hidden from the other classes that are defined in the same package.",
  keywords: "nested classes, static nested classes, non-static nested classes, inner classes, local inner classes, anonymous inner classes, private members, classes, objects",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_exception = {
  path: StrConst.pageurl.javaException + StrConst.URL_INDEX,
  title: "Exceptions in Java",
  description: "Exceptions are unwanted or unexpected events that occur during a program's execution, i.e. at run time, and interrupt the normal flow of the program's instruction. Exceptions are caught and handled programmatically.",
  keywords: "exception, assertion, logging, exception handling, user input error, device, error, exception hierarchy, built-in exception, types of exception, checked exception, unchecked exception",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_exception_handling = {
  path: StrConst.pageurl.javaExceptionHandling + StrConst.URL_INDEX,
  title: "Exception Handling in Java",
  description: "It is important to handle exceptions otherwise the code throws exceptions and the program gets terminated. As we all are aware that when the code throws an exception then the code gets terminated",
  keywords: "exception, exception handling, try, catch, throw throws, finally, Arithmetic Exceptions, UnkownHostException, FileNotFoundException",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_types_of_exceptions = {
  path: StrConst.pageurl.javaTypesOfException + StrConst.URL_INDEX,
  title: "Types of Exceptions in Java",
  description: "Built-in Exceptions are the exceptions that are already available in the Java libraries.",
  keywords: "types of exception, built-in exception, user-defined exception, ArrayIndexOutOfBoundsException, ArithmeticException, ClassNotFoundException, FileNotFoundException, IOException, NoSuchMethodException, NullPointerException, NumberFormatException, StringIndexOutOfBoundsException, IllegalArgumentException, IllegalStateException",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_assertion = {
  path: StrConst.pageurl.javaAssertion + StrConst.URL_INDEX,
  title: "Assertions in Java",
  description: "Assertion in Java is a statement that guarantees the correctness of the assumptions which we have made in the code. We must use the assert keyword to achieve the assertion. When an assertion is executed it must be true. If it fails then JVM throws an error called AssertionError. Assertions are mainly used for testing purpose.",
  keywords: "Assertions, AssertionError, assert",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_collection = {
  path: StrConst.pageurl.javaCollection + StrConst.URL_INDEX,
  title: "Collections in Java",
  description: "A collection is a group of individual objects which are represented as a single unit. A framework is nothing but a platform that provides a base(foundation) for developing applications",
  keywords: "Collection, Collection Framework, Interface, Classes, Algorithms, advantages of Collection, Collection Framework Heirarchy",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_linked_list = {
  path: StrConst.pageurl.javaLinkedList + StrConst.URL_INDEX,
  title: "Concrete Collections in Java - LinkedList",
  description: "LinkedList stores the object in a separate link. Each link stores a reference to the next link in the sequence.",
  keywords: "Collection Framework Hierarchy, Concrete Collection, Collection Framework, data, next, previous, methods of LinkedList",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
};

export const md_java_hashset = {
  path: StrConst.pageurl.javaHashSet + StrConst.URL_INDEX,
  title: "HashSet in Java",
  description: "The well-known data structure that finds the elements quickly is called Hash Table. The hash table functionality is provided by the Class in the Collection Framework called HashSet.",
  keywords: "HashSet, creating a HashSet, importance of HashSet, methods of HashSet",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_treeset = {
  path: StrConst.pageurl.javaTreeSet + StrConst.URL_INDEX,
  title: "TreeSet in Java",
  description: "TreeSet is similar to HashSet, but the difference is, it is a sorted Collection. i.e. The elements inserted in random order will be presented in sorted order. For example, suppose we insert 4 elements in random order and we visit the elements. Then the values of those elements will be displayed in sorted order.",
  keywords: "TreeSet, Syntax of Creating a TreeSet, Methods of TreeSet",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_queue = {
  path: StrConst.pageurl.javaQueue + StrConst.URL_INDEX,
  title: "Queue in Java",
  description: "Queue interface follows the FIFO approach i.e. First In First Out. As the Queue Interface follows the FIFO approach, the elements in the Queue are stored in a sequential manner i.e. in an ordered list where adding or insertion of elements is done at the end and removal or deletion is done at the beginning.",
  keywords: "Queue, FIFO, LinkedList, PriorityQueue, methods of Queue Interface",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_dequeue = {
  path: StrConst.pageurl.javaDequeue + StrConst.URL_INDEX,
  title: "Dequeue Interface in Java",
  description: "Dequeue stands for double-ended Queue, meaning we can add or delete elements from both ends of the data structure.",
  keywords: "Dequeue, LIFO, FIFO, front, rear, methods of Dequeue",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_arraydeque = {
  path: StrConst.pageurl.javaArrayDeque + StrConst.URL_INDEX,
  title: "ArrayDeque in Java",
  description: "ArrayDeque stands for Array Double-Ended Queue. ArrayDequeue grows and allows users to add or remove an element from both sides of the queue. ArrayDeque is present in java. util.ArrayDeque.",
  keywords: "ArrayDequeue Interface, Syntax of ArrayDequeue, Methods of ArrayDequeue",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_map = {
  path: StrConst.pageurl.javaMap + StrConst.URL_INDEX,
  title: "Map Interface in Java",
  description: "Map interface maps the keys with values meaning elements in the Map are stored in key-value pairs. Each key is a unique key associated with individual values. Map cannot contain duplicate keys.",
  keywords: " Map Interface, Syntax of Map Interface, classes that implements Map interface, TreeMa, HashMap, LinkedListMap, EnumMap, WeakHashMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_treemap = {
  path: StrConst.pageurl.javaTreeMap + StrConst.URL_INDEX,
  title: "TreeMap in Java",
  description: "TreeMap implements the Map interface by using a tree. TreeMap is used for storing the elements in key-value pairs in sorted order. Unlike HashMap, TreeMap guarantees that the elements will be sorted in ascending order.",
  keywords: " TreeMap, Syntax of creating TreeMap, Methods of TreeMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_sortedmap = {
  path: StrConst.pageurl.javaSortedMap + StrConst.URL_INDEX,
  title: "SortedMap in Java",
  description: "SortedMap interface extends Map. All the entries in the SortedMap are stored in ascending order. As SortedMap is an interface we can use it directly. In order to use the functionalities of the SortedMap, we can use TreeMap which implements the SortedMap.",
  keywords: "SortedMap, methods of SortedMap, comparator(), firstKey(), lastKey(), headMap(), tailMap(), subMap()",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_navigablemap = {
  path: StrConst.pageurl.javaNavigableMap + StrConst.URL_INDEX,
  title: "NavigableMap Interface in Java",
  description: "NavigableMap interface can be accessed and traversed in ascending or descending order. It is an extension of SortedMap which means it supports the methods of SortedMap along with some new methods.",
  keywords: "NavigableMap, Syntax of NavigableMap, Methods of NavigableMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_hashmap = {
  path: StrConst.pageurl.javaHashMap + StrConst.URL_INDEX,
  title: "HashMap in Java",
  description: "HashMap class is a class that provides the basic implementation of the Map interface. Like in Map, we use key-value pairs to store the data. The same approach is used in HashMap too. One object is used as a key to other objects. The key in the HashMap should be unique",
  keywords: "HashMap, HashMap Constructors, Methods of HashMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_linkedhashmap = {
  path: StrConst.pageurl.javaLinkedHashMap + StrConst.URL_INDEX,
  title: "LinkedHashMap in Java",
  description: "LinkedHashMap works the same as HashMap with additional features. LinkedHashMap provides the ability where elements can be accessed in their insertion order.",
  keywords: "LinkedHashMap, Creating LinkedHashMap, Methods of LinkedHashMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_weakhashmap = {
  path: StrConst.pageurl.javaWeakHashMap + StrConst.URL_INDEX,
  title: "WeakHashMap in Java",
  description: "WeakHashMap is an implementation of the Map interface which stores weak references to its keys. WeakHashMap provides a simple way to harness the power of weak references. It is useful for implementing registry-like data structures, where the utility of an entry vanishes or disappears when its key is no longer reachable by any thread.",
  keywords: "WeakHashMap, WeakHashMap Constructors, Methods of WeakHashmap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_enummap = {
  path: StrConst.pageurl.javaEnumMap + StrConst.URL_INDEX,
  title: "EnumMap in Java",
  description: "EnumMap class is a specialized implementation of Map for enum type. It extends AbstractClass and implements the Map interface. EnumMap class maintains the natural order of their keys.",
  keywords: "EnumMap in Java, Creating EnumMap, Methods of EnumMap",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_thread = {
  path: StrConst.pageurl.javaThread + StrConst.URL_INDEX,
  title: "Threads in Java",
  description: "Thread is a light weighted process or the smallest part of the process which allows the program to operate more efficiently by running multiple tasks simultaneously. All the tasks are executed without affecting the main process.",
  keywords: "Threads in Java, What is a thread, Lifecycle of Thread, Creating a thread, Example of Thread by implementing Runnable Interface, Example of Thread by extending Thread class",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_thread2 = {
  path: StrConst.pageurl.javaThread2 + StrConst.URL_INDEX,
  canonical: StrConst.pageurl.javaThread + StrConst.URL_INDEX,
  title: "Threads in Java Part 2",
  description: "Daemon Thread - Daemon Thread runs as a low-priority thread and is a background service thread that performs background operations like garbage collection, etc. Thread Priorities- Priority is nothing but the importance given to a particular thing. There are three constants used for defining the priorities - MIN_PRIORITY, NORM_PRIORITY, MAX_PRIORITY",
  keywords: "Threads in Java, Thread Properties, Interrupting Thread, Daemon Thread, Handlers for Uncaught Exceptions, Thread Priorities",
  image: "https://www.atrowel.com/assets/img/logo/atrowel-index.jpg",
  imgWidth: "1140",
  imgHeight: "533",
}

export const md_java_advantages_of_lambda_expression = {
  path: StrConst.pageurl.javaAdvantagesOfLambdaExpression + StrConst.URL_INDEX,
  title: "Advantages of Lambda Expression in Java",
  description: "Lambda expressions in Java, introduced in Java 8, allow for the creation of anonymous functions or methods in a succinct manner. They have various benefits that improve code readability, reusability, and flexibility.",
  keywords: " Advantages of Lambda Expression, benefits of Lambda Expression ",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

//JavaScript
export const md_javascript_tutorial = {
  path: StrConst.pageurl.javaScriptTutorial + StrConst.URL_INDEX,
  title: "JavaScript Tutorial",
  description: "JavaScript is the Web programming language. It is used by the vast majority of modern websites, and all modern web browsers—on desktops, game consoles, tablets, and smartphones—include JavaScript interpreters",
  keywords: "JavaScript, Client-Side JavaScript, Server-Side JavaScript, How to Include a JavaScript File in HTML, Features of JavaScript, Application of JavaScript",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_character_set = {
  path: StrConst.pageurl.javaScriptCharacterSet + StrConst.URL_INDEX,
  title: "Character Set in JavaScript  ",
  description: " A character set is a collection of text-representable characters. The character set in JavaScript is Unicode.",
  keywords: "Features of Character Set, Case Sensitivity, Keywords, Object Properties, String Comparison, White Space, Line Break",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

////Removed from ::REJAVASCRIPT
// export const md_case_sentivity = {
//   path: StrConst.pageurl.javaScriptCaseSensitivity + StrConst.URL_INDEX,
//   title: "Case Sensitivity in JavaScript  ",
//   description: " JavaScript is a case-sensitive language which means the keywords, variables, method or function names, identifiers, etc must be with a consistent capitalization of the characters.",
//   keywords: "Case Sensitive, Variable Names, Function Names, Keywords, Object Properties, Naming Conventions",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_whitespace = {
//   path: StrConst.pageurl.javaScriptWhitespaces + StrConst.URL_INDEX,
//   title: "Whitespace, Line Breaks, and Format Control Characters in JavaScript",
//   description: " JavaScript is a case-sensitive language which means the keywords, variables, method or function names, identifiers, etc must be with a consistent capitalization of the characters.",
//   keywords: "Case Sensitive, Variable Names, Function Names, Keywords, Object Properties, Naming Conventions",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

export const md_unicode_escape = {
  path: StrConst.pageurl.javaScriptUnicodeEscape + StrConst.URL_INDEX,
  title: "Unicode Escape Sequences and Normalization in JavaScript",
  description: "Unicode escape sequences in JavaScript are a way to represent characters using a specific syntax. In JavaScript, these escape sequences begin with \\u followed by four hexadecimal digits, which correspond to the Unicode code point of the desired character.",
  keywords: "Unicode, ASCII, Normalization, Normalization Form D, Normalization Form C, Normalization Form KD, Normalization Form KC",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

////Removed from ::REJAVASCRIPT
// export const md_normalization = {
//   path: StrConst.pageurl.javaScriptNormalization + StrConst.URL_INDEX,
//   title: "Normalization in JavaScript",
//   description: "In JavaScript, normalization refers to the process of transforming text containing diverse Unicode characters into a standardized form.",
//   keywords: "Normalization, types of Normalization, Normalization Form D (NFD), Normalization Form C (NFC), Normalization Form KD (NFKD), Normalization Form KC (NFKC)",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

export const md_unicodecharacterset = {
  path: StrConst.pageurl.javaScriptUnicodeCharacterSet + StrConst.URL_INDEX,
  title: "Unicode Character Set in JavaScript",
  description: "In JavaScript, normalization refers to the process of transforming text containing diverse Unicode characters into a standardized form.",
  keywords: "Normalization, types of Normalization, Normalization Form D (NFD), Normalization Form C (NFC), Normalization Form KD (NFKD), Normalization Form KC (NFKC)",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_comments = {
  path: StrConst.pageurl.javaScriptComments + StrConst.URL_INDEX,
  title: "Comments in JavaScript",
  description: "Comments in JavaScript are used to provide explanations, notes, or annotations to your code. The JavaScript interpreter ignores comments, therefore they have no effect on the program's execution.",
  keywords: "Comments, Ways of Adding Comments, Single-Line Comments, Multi-Line Comments",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_literals = {
  path: StrConst.pageurl.javaScriptLiterals + StrConst.URL_INDEX,
  title: "Literals in JavaScript",
  description: "A literal is a syntax in JavaScript for encoding a fixed value directly in source code. It allows you to define values without having to construct a distinct variable or expression.",
  keywords: "What are Literals, Types of Literals, String Literal, Number Literal, Boolean Literal, Array Literals, Object Literal, RegExp Literals, Null Literal, Undefined, Literal, Symbol Literal",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_identifersandreservedwords = {
  path: StrConst.pageurl.javaScriptIdentifiersAndReservedWords + StrConst.URL_INDEX,
  title: "Identifiers and Reserved Words in JavaScript ",
  description: "Identifiers in JavaScript serve as names for variables, functions, and loop labels, while reserved words, or keywords, hold specific meanings reserved within the language.",
  keywords: "Identifiers, What is Identifier, Rules for Identifiers, Examples of Identifiers, Example of Invalid Identifier, Guidelines for choosing Valid Identifiers",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

////Removed from ::REJAVASCRIPT
// export const md_reservedwords = {
//   path: StrConst.pageurl.javaScriptReservedWords + StrConst.URL_INDEX,
//   title: "Reserved Words in JavaScript",
//   description: "Reserved words, also known as keywords in JavaScript, are words with defined meanings that are reserved for certain reasons within the language.",
//   keywords: " What are Reserved Words, Examples of reserved Words ",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_semicolon = {
//   path: StrConst.pageurl.javaScriptSemicolon + StrConst.URL_INDEX,
//   title: "Semicolon in JavaScript",
//   description: " JavaScript employs the semicolon (;), like many other programming languages, to demarcate statements from one another. Without a separator, the end of one sentence could appear to be the beginning of the next, or vice versa, which is vital to avoid in order to make the meaning of your code apparent.",
//   keywords: " What is Semicolon, Key Points of Semicolon, Best Practices of Semicolon, Examples of Semicolon ",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

export const md_numbers = {
  path: StrConst.pageurl.javascriptNumbers + StrConst.URL_INDEX,
  title: "Numbers in JavaScript",
  description: " Unlike many other languages, JavaScript does not distinguish between integer and floating-point values. In JavaScript, all integers are represented as floating-point values.",
  keywords: " What are Numbers, IEEE 754 standard, Integer Literals, Floating-Points Literals, Binary Floating-Point and Rounding Errors",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_string = {
  path: StrConst.pageurl.javascriptString + StrConst.URL_INDEX,
  title: "String in JavaScript",
  description: "JavaScript strings—immutable, ordered sequences of 16-bit values representing Unicode characters, utilizing zero-based indexing, and encapsulated in single quotes, double quotes, or backticks for versatile text storage.",
  keywords: "String Literals, String Methods, substring() Vs slice(), var Vs let, immutable",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_reversestring = {
  path: StrConst.pageurl.javaScriptReverseString + StrConst.URL_INDEX,
  title: "Reverse a String in JavaScript",
  description: " Following are the approaches which can be used for reversing a string: Using for loop, Using split(), reverse() and join(), using Spread Operator",
  keywords: " Reverse a String, Using for loop, Using split(), reverse() and join(), Using for loop, Using substring() and decrementing index, Using Recursion",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_nullandundefined = {
  path: StrConst.pageurl.javaScriptNullAndUndefined + StrConst.URL_INDEX,
  title: "null and undefined in JavaScript",
  description: "null is a language keyword that evaluates to a special value that is usually used to indicate the absence of a value. undefined is a special value that represents the absence of a specific value.",
  keywords: "null Vs undefined, null, undefined, Difference between null and undefined",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_globalobject = {
  path: StrConst.pageurl.javaScriptGlobalObject + StrConst.URL_INDEX,
  title: "Global Object in JavaScript",
  description: "The Global Object provides variables and functions accessible from anywhere in our program. Properties of the global object can be used directly without the need for importing.",
  keywords: "Global Object, globalThis, Global Property",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_wrapperobject = {
  path: StrConst.pageurl.javaScriptWrapperObject + StrConst.URL_INDEX,
  title: "Wrapper Objects in JavaScript",
  description: "The temporary objects created when we access a property of a string,number, or boolean are known as wrapper objects.",
  keywords: "Wrapper Object, new String(), new Number(), new Boolean()",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_primitivevaluesandobjectreferences = {
  path: StrConst.pageurl.javaScriptPrimitiveValuesAndObjectReferences + StrConst.URL_INDEX,
  title: "Primitive Values and Object References in JavaScript",
  description: "In JavaScript, we have two main types of things: primitive values (like undefined, null, booleans, numbers, and strings) and objects (which include arrays and functions)",
  keywords: "Primitive Values, Object References, Immutability of Primitive Values, Mutability of objects",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_typeconversion = {
  path: StrConst.pageurl.javaScriptTypeConversion + StrConst.URL_INDEX,
  title: "Type Conversion in JavaScript",
  description: "Type Conversion is nothing but the conversion of a value from one datatype to another. In JavaScript, we can change the datatype of any variable from one to another.",
  keywords: "Implicit Type Conversion, Explicit Type Conversion, Conversion, Equality",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

export const md_objecttoprimitiveconversion = {
  path: StrConst.pageurl.javaScriptObjectToPrimitiveConversion + StrConst.URL_INDEX,
  title: "Object to Primitive Conversion in JavaScript",
  description: "In JavaScript, Object to Primitive Conversion is basically the process of converting an object to primitive values such as a string, number, or boolean when needed in a situation that expects a basic value.",
  keywords: "Implicit Type Conversion, Explicit Type Conversion, Conversion, Equality",
  image: StrConst.OPENGRAPH_IMG,
  imgWidth: StrConst.OPENGRAPH_WIDTH,
  imgHeight: StrConst.OPENGRAPH_HIGHT,
}

// -------------------------------------------------

////Removed from ::REJAVASCRIPT
// export const md_mathobject = {
//   path: StrConst.pageurl.javaScriptMathObject + StrConst.URL_INDEX,
//   title: "Math Object in JavaScript",
//   description: " In JavaScript, the Math object is a built-in object that provides a library of mathematical functions and constants. It enables the execution of numerous mathematical operations and calculations in JavaScript programmes.",
//   keywords: " Math Object, pow(), ceil(), round(), floor(), abs(), max(), min(), round(), PI, E, sqrt(), exp()",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_dateobject = {
//   path: StrConst.pageurl.javaScriptDateObject + StrConst.URL_INDEX,
//   title: "Date Object in JavaScript",
//   description: " Core JavaScript includes a Date() constructor for generating objects that reflect dates and times. These Date objects provide methods that provide an API for simple date computations.",
//   keywords: " What is Date Object, Creating a Date object, Accessing Date Component, Setting Date Component, Formatting dates, Method of Date",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_dateconversion = {
//   path: StrConst.pageurl.javaScriptDateConversion + StrConst.URL_INDEX,
//   title: "Date Object in JavaScript",
//   description: " Core JavaScript includes a Date() constructor for generating objects that reflect dates and times. These Date objects provide methods that provide an API for simple date computations.",
//   keywords: " What is Date Object, Creating a Date object, Accessing Date Component, Setting Date Component, Formatting dates, Method of Date",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_stringmethods = {
//   path: StrConst.pageurl.javaScriptStringMethods + StrConst.URL_INDEX,
//   title: "String Methods in JavaScript",
//   description: " JavaScript provides a variety of methods for working with strings. Here are some common methods and operations you can perform on JavaScript strings:",
//   keywords: " Methods of String, length, toUpperCase(), toLowerCase(), indexOf(), substring(), slice(), replace(), split(), trim(), charAt(), startsWith(), endsWith(), includes(), concat(), localeCompare()",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }


////Removed from ::REJAVASCRIPT
// export const md_datatypes = {
//   path: StrConst.pageurl.javaScriptDatatypes + StrConst.URL_INDEX,
//   title: "Data Types in JavaScript",
//   description: "Datatypes in JavaScript relate to the different types of data that can be utilized and processed within the language. The types of values that can be assigned to variables, how they behave, and the actions that can be performed on them are defined by data types.",
//   keywords: " What is Semicolon, Key Points of Semicolon, Best Practices of Semicolon, Examples of Semicolon ",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_primitivedatatypes = {
//   path: StrConst.pageurl.javascriptPrimitiveDatatypes + StrConst.URL_INDEX,
//   title: "Primitive Data Types in JavaScript",
//   description: "Following are the Primitive Data Types: 1. Number 2. String 3. Boolean 4. Null 5. Undefined 6. Symbol 7. BigInt",
//   keywords: " Primitive Data Types in JavaScript, Numbers, String, Boolean, Null, Undefined, Symbol, BigInt ",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_nonprimitivedatatypes = {
//   path: StrConst.pageurl.javascriptNonPrimitiveDatatypes + StrConst.URL_INDEX,
//   title: "Non-Primitive Data Types in JavaScript",
//   description: "Non-primitive data types, (also known as reference data types or composite data types), are data types that do not directly hold a value but instead hold a reference or memory address to the location where the data is stored",
//   keywords: "Non-Primitive Data Types, What is Object, key-value pairs, Key Points about Object",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }

////Removed from ::REJAVASCRIPT
// export const md_array = {
//   path: StrConst.pageurl.javascriptArray + StrConst.URL_INDEX,
//   title: "Array in JavaScript",
//   description: "An array is a specialized data structure in JavaScript that is used to store a group of elements(collection of elements). These elements can be of any data type, such as numbers, strings, objects, arrays, etc.",
//   keywords: "Array in JavaScript, how to create or declare an array in javascript,  how to initialize an array in javascript, how to access an array in javascript,  how to modify or update an array in javascript, how to find the length of an array in javascript,  how to add or push the elements in an array in javascript, how to push or add the elements in an array using unshift() in javascript, how to delete or remove the elements in an array in javascript, how to remove or delete the elements in an array using shift() in javascript,  how to loop an array in javascript",
//   image: StrConst.OPENGRAPH_IMG,
//   imgWidth: StrConst.OPENGRAPH_WIDTH,
//   imgHeight: StrConst.OPENGRAPH_HIGHT,
// }